<template>
  <div>
    <b-modal
      :id="componentName"
      no-close-on-backdrop
      hide-footer
      centered
      button-size="sm"
      @shown="btnModal('show')"
      @hide="btnModal('close')"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          상세설정
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="$bvModal.hide(componentName)"
          />
        </div>
      </template>

      <!-- Modal Body -->
      <template #default>
        <b-card no-body>
          <!-- Modal Body-Title -->
          <b-row>
            <b-col>
              <div>
                <h4>
                  <feather-icon
                    icon="BoxIcon"
                    style="width: 18px; height: 18px"
                  />
                  등급 상세설정 : <span class="font-small-5 text-info"> {{ selectedRank.rankName }} </span>
                  <b-badge
                    variant="primary"
                    style="font-size: xx-small"
                  >
                    DB 연계
                  </b-badge>
                </h4>
              </div>
            </b-col>
          </b-row>
          <hr>
          <!-- Modal Body-Contents -->
          <b-row>
            <b-col>
              <div>
                <b-tabs
                  v-model="activeTab"
                  class="p-1"
                >
                  <b-tab
                    title="상세설정"
                  >
                    <b-card
                      no-body
                    >
                      <b-row
                        class="mt-1"
                      >
                        <b-col>
                          <b-table
                            id="tbSetRankDetail"
                            small
                            responsive
                            bordered
                            show-empty
                            empty-text="No matching records found"
                            :items="fetchSetRankDetail"
                            :fields="tab1Fields"
                            style="text-align: center !important; font-size: small;"
                          >
                            <template #thead-top>
                              <b-tr>
                                <!-- 첫 번째 행 -->
                                <b-th
                                  rowspan="2"
                                  style="vertical-align: bottom; width: 20rem; background-color: #333639"
                                >
                                  GAME
                                </b-th>
                                <b-th colspan="5">
                                  본인 낙찰시(%)
                                </b-th>
                                <b-th
                                  colspan="5"
                                  style="background-color: #333639"
                                >
                                  본인 배팅시(%)
                                </b-th>
                              </b-tr>
                              <b-tr>
                                <!-- 두 번째 행 -->
                                <b-th>1폴</b-th>
                                <b-th>2폴</b-th>
                                <b-th>3폴</b-th>
                                <b-th>4폴</b-th>
                                <b-th>5폴</b-th>
                                <b-th style="background-color: #333639">
                                  1폴
                                </b-th>
                                <b-th style="background-color: #333639">
                                  2폴
                                </b-th>
                                <b-th style="background-color: #333639">
                                  3폴
                                </b-th>
                                <b-th style="background-color: #333639">
                                  4폴
                                </b-th>
                                <b-th style="background-color: #333639">
                                  5폴
                                </b-th>
                              </b-tr>
                            </template>

                            <template #cell(myWin1)="data">
                              <b-form-input
                                v-if="fetchSetRankDetail"
                                :value="data.item.myWin1"
                                type="text"
                                size="sm"
                                class="center"
                                style="text-align: right; color:rgb(229,166,48); min-width: 4rem"
                                @input="$emit('updateParam', 'fetchSetRankDetail','myWin1', $event, data.index)"
                              />
                            </template>
                            <template #cell(myWin2)="data">
                              <b-form-input
                                v-if="fetchSetRankDetail"
                                :value="data.item.myWin2"
                                type="number"
                                size="sm"
                                style="text-align: right; color:rgb(229,166,48); min-width: 4rem"
                                @input="$emit('updateParam', 'fetchSetRankDetail','myWin2', $event, data.index)"
                              />
                            </template>
                            <template #cell(myWin3)="data">
                              <b-form-input
                                v-if="fetchSetRankDetail"
                                :value="data.item.myWin3"
                                type="number"
                                size="sm"
                                style="text-align: right; color:rgb(229,166,48); min-width: 4rem"
                                @input="$emit('updateParam', 'fetchSetRankDetail','myWin3', $event, data.index)"
                              />
                            </template>
                            <template #cell(myWin4)="data">
                              <b-form-input
                                v-if="fetchSetRankDetail"
                                :value="data.item.myWin4"
                                type="number"
                                size="sm"
                                style="text-align: right; color:rgb(229,166,48); min-width: 4rem"
                                @input="$emit('updateParam', 'fetchSetRankDetail','myWin4', $event, data.index)"
                              />
                            </template>
                            <template #cell(myWin5)="data">
                              <b-form-input
                                v-if="fetchSetRankDetail"
                                :value="data.item.myWin5"
                                type="number"
                                size="sm"
                                style="text-align: right; color:rgb(229,166,48); min-width: 4rem"
                                @input="$emit('updateParam', 'fetchSetRankDetail','myWin5', $event, data.index)"
                              />
                            </template>

                            <template #cell(myBet1)="data">
                              <b-form-input
                                v-if="fetchSetRankDetail"
                                :value="data.item.myBet1"
                                type="number"
                                size="sm"
                                style="text-align: right; color:rgb(229,166,48); min-width: 4rem"
                                @input="$emit('updateParam', 'fetchSetRankDetail','myBet1', $event, data.index)"
                              />
                            </template>
                            <template #cell(myBet2)="data">
                              <b-form-input
                                v-if="fetchSetRankDetail"
                                :value="data.item.myBet2"
                                type="number"
                                size="sm"
                                style="text-align: right; color:rgb(229,166,48); min-width: 4rem"
                                @input="$emit('updateParam', 'fetchSetRankDetail','myBet2', $event, data.index)"
                              />
                            </template>
                            <template #cell(myBet3)="data">
                              <b-form-input
                                v-if="fetchSetRankDetail"
                                :value="data.item.myBet3"
                                type="number"
                                size="sm"
                                style="text-align: right; color:rgb(229,166,48); min-width: 4rem"
                                @input="$emit('updateParam', 'fetchSetRankDetail','myBet3', $event, data.index)"
                              />
                            </template>
                            <template #cell(myBet4)="data">
                              <b-form-input
                                v-if="fetchSetRankDetail"
                                :value="data.item.myBet4"
                                type="number"
                                size="sm"
                                style="text-align: right; color:rgb(229,166,48); min-width: 4rem"
                                @input="$emit('updateParam', 'fetchSetRankDetail','myBet4', $event, data.index)"
                              />
                            </template>
                            <template #cell(myBet5)="data">
                              <b-form-input
                                v-if="fetchSetRankDetail"
                                :value="data.item.myBet5"
                                type="number"
                                size="sm"
                                style="text-align: right; color:rgb(229,166,48); min-width: 4rem"
                                @input="$emit('updateParam', 'fetchSetRankDetail','myBet5', $event, data.index)"
                              />
                            </template>

                          </b-table>
                        </b-col>
                      </b-row>

                    </b-card>
                  </b-tab>
                  <b-tab title="계좌 설정">
                    <b-card
                      no-body
                    >
                      <b-row
                        class="mt-1"
                      >
                        <b-col>
                          <b-row>
                            <b-col
                              cols="12"
                              md="4"
                            >
                              <b-form-group
                                label="계좌은행"
                                label-for="user-adminAccountBank"
                              >
                                <b-form-input
                                  id="user-accountBank"
                                  v-model="fetchRankAccount.adminAccountBank"
                                  class="font-small-3"
                                  placeholder="은행명만 기입"
                                  style="color:rgb(229,166,48)"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="12"
                              md="4"
                            >
                              <b-form-group
                                label="계좌번호"
                                label-for="user-adminAccountNumber"
                              >
                                <b-form-input
                                  id="user-accountNumber"
                                  v-model="fetchRankAccount.adminAccountNumber"
                                  class="font-small-3"
                                  placeholder="ex)123-456-789"
                                  style="color:rgb(229,166,48)"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="12"
                              md="4"
                            >
                              <b-form-group
                                label="예금주"
                                label-for="user-adminAccountName"
                              >
                                <b-form-input
                                  id="user-username"
                                  v-model="fetchRankAccount.adminAccountName"
                                  class="font-small-3"
                                  placeholder="홍길동"
                                  style="color:rgb(229,166,48)"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>
                </b-tabs>
              </div>
            </b-col>
          </b-row>
          <!-- Modal Body-Button -->
          <b-row>
            <b-col
              cols="12"
            >
              <div
                class="pr-1"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                  size="sm"
                  class="float-right"
                  @click="submit"
                >
                  저장
                </b-button>
                <b-button
                  ref="reset"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="reset"
                  variant="primary"
                  size="sm"
                  class="float-right mr-2"
                  @click="$bvModal.hide(componentName)"
                >
                  취소
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- Check data -->
          <div v-if="false">
            <hr>
            <p><b>selectedRank: </b> {{ selectedRank }} </p>
            <p><b>fetchRankAccount: </b> {{ fetchRankAccount }} </p>
            <p><b>fetchSetRankDetail: </b> {{ fetchSetRankDetail }} </p>
          </div>
        </b-card>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BFormGroup, BButton,
  BFormInput,
  BTable, BTh, BTr,
  BModal,
  BTabs, BTab,

  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_SET_RANK_DETAIL,
} from '@/store/settings/action'

import {
  UPDATEMANY_SET_RANK_DETAIL,
  UPDATE_SET_RANK_ACCOUNT,
} from '@/store/settings/mutation'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  name: 'RankSettingsDetail',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BTable,
    BTh,
    BTr,
    BModal,
    BTabs,
    BTab,

    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    selectedRank: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,
      userData: JSON.parse(localStorage.getItem('userData')),
      userIp: localStorage.getItem('ip'),
      // Tabs
      activeTab: 0, // 기본적으로 첫 번째 탭을 활성화
      loadedTabs: [], // 각 탭의 데이터 로딩 상태 추적

      // Tab_1
      // Table

      tab1Fields: [
        { key: 'gameName', sortable: false, label: 'GAME', thStyle: { width: '20rem', backgroundColor: '#333639' } },
        { key: 'myWin1', sortable: false, label: '1폴' },
        { key: 'myWin2', sortable: false, label: '2폴' },
        { key: 'myWin3', sortable: false, label: '3폴' },
        { key: 'myWin4', sortable: false, label: '4폴' },
        { key: 'myWin5', sortable: false, label: '5폴' },
        { key: 'myBet1', sortable: false, label: '1폴' },
        { key: 'myBet2', sortable: false, label: '2폴' },
        { key: 'myBet3', sortable: false, label: '3폴' },
        { key: 'myBet4', sortable: false, label: '4폴' },
        { key: 'myBet5', sortable: false, label: '5폴' },
      ],

      // 등급별 계좌설정
      fetchRankAccount: {},
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchSetRank: 'fetchSetRank',
      fetchSetRankDetail: 'fetchSetRankDetail',
    }),
  },
  beforeCreate() {
    console.clear()
    console.log('컴포넌트가 생성되기 전에 콘솔이 클리어됩니다.')
    console.log(this.componentName, 'beforeCreate()')
  },
  created() {
    console.log(this.componentName, 'created()')
  },
  mounted() {
    console.log(this.componentName, 'mounted()')
  },
  beforeDestroy() {
    console.clear()
    console.log(this.componentName, 'beforeDestroy()')
    console.log('컴포넌트가 제거된 후에 콘솔이 클리어됩니다.')
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchSetRankDetail: FETCH_SET_RANK_DETAIL,
      $updateManySetRankDetail: UPDATEMANY_SET_RANK_DETAIL,
      $updateSetRankAccount: UPDATE_SET_RANK_ACCOUNT,
    }),

    async _fetchDataForTab(tabIndex) {
      if (this.loadedTabs[tabIndex]) {
        return // 데이터가 이미 로드되었으면 여기서 종료
      }

      switch (tabIndex) {
        case 0: { // Tab1에 대한 데이터 로딩 로직
          // await this.$fetchSetRankDetail({
          //   site: this.selectedRank.site,
          //   rankName: this.selectedRank.rankName,
          // })
          break
        }
        case 1: { // Tab2에 대한 데이터 로딩 로직
          this.rankAccountInfo = {
            index: this.selectedRank.index || '',
            adminAccountBank: this.selectedRank.adminAccountBank || '',
            adminAccountNumber: this.selectedRank.adminAccountNumber || '',
            adminAccountName: this.selectedRank.adminAccountName || '',
          }
          break
        }
        default:
          break
      }

      // 데이터 로딩이 완료되면 해당 탭의 상태를 true로 설정
      this.$set(this.loadedTabs, tabIndex, true)
    },

    async submit() {
      const result = await Swal.fire({
        title: '변경 내용을 저장 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      })

      if (result.isConfirmed) {
        const updatePromises = [
          await this.$updateManySetRankDetail({
            site: this.selectedRank.site,
            rankName: this.selectedRank.rankName,
            userid: this.userData.userid,
            userIp: this.userIp,
          }),
          await this.$updateSetRankAccount({
            site: this.selectedRank.site,
            rankName: this.selectedRank.rankName,
            userid: this.userData.userid,
            userIp: this.userIp,
            ...this.fetchRankAccount,
          }),
        ]

        Promise.all(updatePromises)
          .then(results => {
            // 모든 비동기 작업이 성공적으로 완료되면 여기서 결과 처리
            Swal.fire({
              title: '저장 완료',
              icon: 'success',
              timer: 5000,
              background: 'rgb(3,11,37)',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
            // this.$parent.fetchData()
            this.btnModal('close')
            console.log('모든 업데이트가 성공적으로 완료되었습니다.', results)
          })
          .catch(error => {
            // 하나라도 실패하면 여기서 에러 처리
            Swal.fire({
              title: '저장 실패',
              text: '시스템 관리자에게 문의 바랍니다.',
              icon: 'error',
              background: 'rgb(3,11,37)',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            })
            console.error('업데이트 중 오류가 발생했습니다:', error)
          })
      }
    },
    // Modal
    btnModal(action) {
      if (action === 'show') {
        // 모달 표시 로직
        // this.fetchDataForTab(this.activeTab)
        this.fetchRankAccount = {
          index: this.selectedRank.index || '',
          adminAccountBank: this.selectedRank.adminAccountBank || '',
          adminAccountNumber: this.selectedRank.adminAccountNumber || '',
          adminAccountName: this.selectedRank.adminAccountName || '',
        }
      }
      if (action === 'close') {
        // 데이터 초기화
        // this.loadedTabs = []
        this.activeTab = 0
        this.rankAccountInfo = {}

        // 실제 모달 닫기 동작
        this.$bvModal.hide(this.componentName)
        console.log(this.componentName, 'closed')
      }
    },
  },
}
</script>

<style>
/* 모달 사이즈 조정 */
.modal-dialog {
  max-width: 80% !important; /* 우선 순위를 높이기 위해 !important 추가 */
}

/* 모달 위치 조정 */
.modal-dialog-centered {
  align-items: flex-start;
  margin-top: calc(100vh / 4); /* 브라우저 높이의 1/4 위치에서 시작 */
}

/* 테이블 헤더 제거  */
#tbSetRankDetail thead tr:last-child {
  display: none;
}

</style>
